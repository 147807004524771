import React, {
  createContext,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  UserCredential,
} from "firebase/auth";
import { firebaseConfig } from "../services/configuracion.service";
import { verifyLogin } from "../services/firebase.service";
import { uuidv4 } from "@firebase/util";
import {
  ChannelDigitalLetter,
  DigitalLetter,
  Item,
  ModifierGroup,
} from "../models/orders";
import { Cart, ItemsCart, Price, SelectedModifierGroup } from "../models/cart";
import { precioFormat } from "../functions";
import { getAnalytics } from "firebase/analytics";
import { LoginGoogle } from "../services/storage.service";
import { getCards, getUsers } from "../services/users.service";
import { themes } from "../config/Icons";



// interface User {
//   store: string;
//   idStore: (idStore: any) => void;
//   mesa: string;
//   idMesa: (idMesa: any) => void;
//   estado: boolean;
//   idEstado: (estado: boolean) => void;
// }

const appfirebase = initializeApp(firebaseConfig);

export const auth = getAuth(appfirebase);
const analytics = getAnalytics(appfirebase);
auth.languageCode = "pe";

export const UserContext = createContext</*User |*/ any>(null);

export const UserProvider = (props: any) => {
  getRedirectResult(auth)
    .then((result) => {
      if (result !== null) {
        verifyLogin(result);
      }
    })
    .catch((error) => {
      console.log(error);
    });

  const [store, setStore] = useState(localStorage.getItem("idstore") || "");
  const [mesa, setMesa] = useState(localStorage.getItem("idmesa"));
  const [nameMesa, setNameMesa] = useState( localStorage.getItem("name_mesa") || "");
  const [estado, setEstado] = useState(false);
  const [estadoModal, setEstadoModal] = useState(false);
  const [itemCount, setItemCount] = useState(1);
  const [price, setPrice] = useState(0);
  const [priceTotal, setPriceTotal] = useState(0);
  const [client, setClient] = useState("");
  const [tokenUser, setTokenUser] = useState(localStorage.getItem("token_session"));
  const [expirationSession, setExpirationSession] = useState(
    localStorage.getItem("expiration_session")
  );
  // const storedDigitalLetter = localStorage.getItem("digital_letter");
  // const [digitalLetter, setDigitalLetter] = useState<DigitalLetter>();
  const [digitalLetter, setDigitalLetter] = useState<DigitalLetter>(() => {
    const storedDigitalLetter = localStorage.getItem("digital_letter");
    if (storedDigitalLetter) {
      try {
        return JSON.parse(storedDigitalLetter);
      } catch (error) {
        console.error(
          "Error al parsear digitalLetter desde localStorage",
          error
        );
        return {
          landing_picture: "",
          landing_gradient_start: "",
          landing_gradient_end: "",
          adicional_text_1: "",
          adicional_text_2: "",
          adicional_text_3: "",
          isCart: false,
          isLogin: false,
          isSeller: false,
          isGourmet: false,
          isSelectOrder: false,
          isActiveChannel1: false,
          isActiveChannel2: false,
          isActiveChannel3: false,
          isActiveChannel4: false,
          isActiveChannel5: false,
          isTipPercentage: false,
          isRequiredNameClient: false,
        }; // Valor predeterminado en caso de error al parsear
      }
    }
    return {
      landing_picture: "",
      landing_gradient_start: "",
      landing_gradient_end: "",
      adicional_text_1: "",
      adicional_text_2: "",
      adicional_text_3: "",
      isCart: false,
      isLogin: false,
      isSeller: false,
      isGourmet: false,
      isSelectOrder: false,
      isActiveChannel1: false,
      isActiveChannel2: false,
      isActiveChannel3: false,
      isActiveChannel4: false,
      isActiveChannel5: false,
      isTipPercentage: false,
      isRequiredNameClient:false,
    }; // Valor predeterminado si no hay datos en localStorage
  });

  const [optionsChannel, setOptionsChannel] = useState<ChannelDigitalLetter[]>(
    []
  );
  const [IsCart, setIsCart] = useState(localStorage.getItem("is_cart"));
  const [isLogin, setIsLogin] = useState(localStorage.getItem("is_login"));
  const [culqiSecret, setCulqiSecret] = useState("");

  const [loginVisble, setLoginVisible] = useState(localStorage.getItem("token_session") === undefined && localStorage.getItem("token_session") === null);
  const [userToken, setUserToken] = useState(localStorage.getItem("user_token") );
  const [tokenExpiration, setTokenExpiration] = useState(localStorage.getItem("token_expiration"));

  const [customerCulqiId, setCustomerCulqiId] = useState(null);
  const [tarjetas, setTarjetas] = useState([]);
  const [metodoLogeo, setMetodoLogeo] = useState("");
  //cambio de color
  const [ThemesColors, setCurrentColors] = useState({activo: themes.light.colors.Primarios, pasivo: themes.light.colors.Secundarios});
  // Activar Carta Gourmet
  const [isGourmet, setIsGourmet] = useState(false);
  //Agregar nombre al Usuario 
  const [name, setName] = useState(localStorage.getItem("userName") || "");
  
  //Agregar canal seleccionado
  const [channelSelect, setChannelSelect] = useState<ChannelDigitalLetter>(JSON.parse(localStorage.getItem("channelSelected") || "[]"));
  //cambio de color dinamico
  useEffect(() => {
    const storedColors = localStorage.getItem("colors")!;
    if (storedColors) {
      setCurrentColors(JSON.parse(storedColors)); // Parse the user back to an object
    }
  }, []);

    // Sincroniza el nombre del usuario con localStorage
    useEffect(() => {
      localStorage.setItem("userName", name);
    }, [name]);

  // Save colors to localStorage whenever ThemesColors y Gourmet changes
  useEffect(() => {
    localStorage.setItem("colors", JSON.stringify(ThemesColors));
  }, [ThemesColors]); 


  useEffect(() => {
    if (isGourmet !== null) {
      localStorage.setItem("isGourmet", JSON.stringify(isGourmet));
    }
  }, [isGourmet]);



  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile email");
    signInWithPopup(auth, provider).then((res: any) => {
      const user = res.user;
      // console.log(user?.providerData[0]);

      LoginGoogle(user.providerData[0]).then(async (result) => {
        console.log(`Guardando token en storage ${result?.data.token}`);

        localStorage.setItem("user_token", result?.data.token!);
        localStorage.setItem(
          "token_expiration",
          JSON.stringify(result?.data.expiration!)
        );

        const clienteExiste = await getUsers(result?.data.token!);

        if (clienteExiste?.codResponse === 0) {
          localStorage.setItem(
            "culqi_customer",
            clienteExiste?.data.idCulqiCustomer
          );
          setCustomerCulqiId(clienteExiste?.data.idCulqiCustomer);
        }

        idTokenUser(result?.data.token!, result?.data.token!);

        const tarjetas = await getCards(result?.data.token!);

        if (tarjetas?.codResponse === 0) {
          setTarjetas(tarjetas?.data);
        }

        if (
          result?.data.token !== undefined &&
          result?.data.token !== "" &&
          result?.data.token !== null
        ) {
          setLoginVisible(false);
        }
      });
    });
  };

  const uuid = uuidv4();
  // CARRO PRINCIPAL
  const [basket, setBasket] = useState<Cart>(() => {
    const products: ItemsCart[] = [];
    const cart: Cart = {
      id_store: "",
      store_name: "",
      items: products,
      special_instructions: "",
    };
    const storedValue =
      JSON.parse(localStorage.getItem("basket") || "[]") ?? [];

    if (storedValue) {
      cart.id_store = storedValue.id || localStorage.getItem("idstore")!;
      cart.store_name =
        storedValue.store_name || localStorage.getItem("infoClient")!;
      cart.items = storedValue.items || [];
      cart.special_instructions = storedValue.special_instructions || "";
    }
    return cart;
  });

  const storeBasket = (product: any) => {
    // product.quantity = 1;
    setBasket({ ...basket, items: [...basket.items, product] });
    localStorage.setItem(
      "basket",
      JSON.stringify({ ...basket, items: [...basket.items, product] }) || "[]"
    );
  };

  const updateBasket = (product: any, index: number) => {
    // product.quantity = 1;
    if (index !== -1) {
      basket.items[index] = product;
    }
    setBasket({ ...basket, items: basket.items });
    localStorage.setItem(
      "basket",
      JSON.stringify({ ...basket, items: basket.items }) || "[]"
    );
  };

  const deleteBasket = () => {
    localStorage.removeItem("basket");

    const products: ItemsCart[] = [];
    const cart: Cart = {
      id_store: "",
      store_name: "",
      items: products,
      special_instructions: "",
    };

    const storedValue =
      JSON.parse(localStorage.getItem("basket") || "[]") ?? [];

    if (storedValue) {
      cart.id_store = storedValue.id || localStorage.getItem("idstore")!;
      cart.store_name =
        storedValue.store_name || localStorage.getItem("infoClient")!;
      cart.items = storedValue.items || [];
      cart.special_instructions = storedValue.special_instructions || "";
    }

    setBasket(cart);
  };

  const deleteElementFromBasket = (indexid: number) => {
    const products: ItemsCart[] = basket.items.filter(
      (bas: any, index: number) => index !== indexid
    );
    setBasket((prevState) => ({ ...prevState, items: products }));
    localStorage.setItem(
      "basket",
      JSON.stringify({ ...basket, items: products }) || "[]"
    );
  };

  const addOrRemoveProduct = (indexid: any, add: boolean) => {
    const products = basket.items.map((product: any, index: number) => {
      if (indexid === index) {
        const beforequantity = product.quantity;
        if (add) {
          product.quantity += 1;
        } else {
          if (product.quantity > 1) {
            product.quantity -= 1;
          }
        }

        const priceactual: Price = product.price;
        const price: Price = {
          id: null,
          base_total_price:
            precioFormat(
              product.id!,
              (priceactual.base_total_price.amount / beforequantity) *
                product.quantity
            ) || 0,
          total_price:
            precioFormat(
              product.id!,
              (priceactual.total_price.amount / beforequantity) *
                product.quantity
            ) || 0,
          base_unit_price:
            precioFormat(
              product.id!,
              (priceactual.base_unit_price.amount / beforequantity) *
                product.quantity
            ) || 0,
          unit_price: precioFormat(
            product.id!,
            (priceactual.unit_price.amount / beforequantity) *
              product.quantity || 0
          ),
        };
        product.price = price;
      }
      return {
        ...product,
      };
    });
    setBasket((prevState) => ({ ...prevState, items: products }));
    localStorage.setItem(
      "basket",
      JSON.stringify({ ...basket, items: products }) || "[]"
    );
  };

  // MODIFICADORES
  const [basketModifier, setBasketModifier] = useState<any>(() => {
    const modifiercart: SelectedModifierGroup[] = [];
    const modifiergroups = {
      selected_modifier_groups: modifiercart,
    };
    const modifierValue =
      JSON.parse(localStorage.getItem("basketModifier") || "[]") ?? [];

    if (modifierValue) {
      modifiergroups.selected_modifier_groups =
        modifierValue.selected_modifier_groups || [];
    }

    return modifiergroups;
  });

  const storeBasketModifier = (productModifier: any) => {
    setBasketModifier({
      ...basketModifier,
      selected_modifier_groups: [
        ...basketModifier.selected_modifier_groups,
        productModifier,
      ],
    });
    localStorage.setItem(
      "basketModifier",
      JSON.stringify({
        ...basketModifier,
        selected_modifier_groups: [
          ...basketModifier.selected_modifier_groups,
          productModifier,
        ],
      }) || "[]"
    );
  };

  const emptyBasketModifiers = () => {
    const productsModifier: SelectedModifierGroup[] = [];
    setBasketModifier((prevState: any) => ({
      ...prevState,
      selected_modifier_groups: productsModifier,
    }));
    localStorage.setItem(
      "basketModifier",
      JSON.stringify({
        ...basketModifier,
        selected_modifier_groups: productsModifier,
      }) || "[]"
    );
  };

  const storeAllBasketModifier = (productModifier: any) => {
    setBasketModifier({ selected_modifier_groups: productModifier });
    localStorage.setItem(
      "basketModifier",
      JSON.stringify({ selected_modifier_groups: productModifier }) || "[]"
    );
  };

  const addModifier = (productModifier: any) => {
    if (productModifier.selected_items.length > 0) {
      const modifierOptions = basketModifier.selected_modifier_groups.map(
        (modifieroption: any) => {
          if (productModifier.external_data === modifieroption.external_data) {
            modifieroption.selected_items = productModifier.selected_items;
          }
          return {
            ...modifieroption,
          };
        }
      );
      setBasketModifier({ selected_modifier_groups: modifierOptions });
      localStorage.setItem(
        "basketModifier",
        JSON.stringify({ selected_modifier_groups: modifierOptions }) || "[]"
      );
    } else {
      const indexproductModifier = basketModifier.selected_modifier_groups
        .map((bm: any) => bm.external_data)
        .indexOf(productModifier.external_data);
      basketModifier.selected_modifier_groups.splice(indexproductModifier, 1);
      setBasketModifier({
        selected_modifier_groups: basketModifier.selected_modifier_groups,
      });
      localStorage.setItem(
        "basketModifier",
        JSON.stringify({
          selected_modifier_groups: basketModifier.selected_modifier_groups,
        }) || "[]"
      );
    }
  };

  //EDITAR MODIFIERS
  const [basketModifierEditar, setBasketModifierEditar] = useState<any>(() => {
    const modifiercart: SelectedModifierGroup[] = [];
    const modifiergroups = {
      selected_modifier_groups: modifiercart,
    };
    const modifierValue =
      JSON.parse(localStorage.getItem("basketModifierEditar") || "[]") ?? [];

    if (modifierValue) {
      modifiergroups.selected_modifier_groups =
        modifierValue.selected_modifier_groups || [];
    }

    return modifiergroups;
  });

  const storeBasketModifierEditar = (productModifier: any) => {
    setBasketModifierEditar({
      ...basketModifierEditar,
      selected_modifier_groups: [
        ...basketModifierEditar.selected_modifier_groups,
        productModifier,
      ],
    });
    localStorage.setItem(
      "basketModifierEditar",
      JSON.stringify({
        ...basketModifierEditar,
        selected_modifier_groups: [
          ...basketModifierEditar.selected_modifier_groups,
          productModifier,
        ],
      }) || "[]"
    );
  };

  const emptyBasketModifiersEditar = () => {
    const productsModifier: SelectedModifierGroup[] = [];
    setBasketModifierEditar((prevState: any) => ({
      ...prevState,
      selected_modifier_groups: productsModifier,
    }));
    localStorage.setItem(
      "basketModifierEditar",
      JSON.stringify({
        ...basketModifierEditar,
        selected_modifier_groups: productsModifier,
      }) || "[]"
    );
  };

  const storeAllBasketModifierEditar = (productModifier: any) => {
    setBasketModifierEditar({ selected_modifier_groups: productModifier });
    localStorage.setItem(
      "basketModifierEditar",
      JSON.stringify({ selected_modifier_groups: productModifier }) || "[]"
    );
  };

  const addModifierEditar = (productModifier: any) => {
    if (productModifier.selected_items.length > 0) {
      const modifierOptions = basketModifierEditar.selected_modifier_groups.map(
        (modifieroption: any) => {
          if (productModifier.external_data === modifieroption.external_data) {
            modifieroption.selected_items = productModifier.selected_items;
          }
          return {
            ...modifieroption,
          };
        }
      );
      setBasketModifierEditar({ selected_modifier_groups: modifierOptions });
      localStorage.setItem(
        "basketModifierEditar",
        JSON.stringify({ selected_modifier_groups: modifierOptions }) || "[]"
      );
    } else {
      const indexproductModifier = basketModifierEditar.selected_modifier_groups
        .map((bm: any) => bm.external_data)
        .indexOf(productModifier.external_data);
      basketModifierEditar.selected_modifier_groups.splice(
        indexproductModifier,
        1
      );
      setBasketModifierEditar({
        selected_modifier_groups: basketModifierEditar.selected_modifier_groups,
      });
      localStorage.setItem(
        "basketModifierEditar",
        JSON.stringify({
          selected_modifier_groups:
            basketModifierEditar.selected_modifier_groups,
        }) || "[]"
      );
    }
  };

  //SELECCION DE MODIFICADORES (LOCALSTORAGE)
  const [checkModifierOption, setCheckModifierOption] = useState<any>(() => {
    const chkm: any[] = [];
    const checkmodifiers = {
      checkmodifiers: chkm,
    };
    const checkmodifierValue =
      JSON.parse(localStorage.getItem("checkModifier") || "[]") ?? [];

    if (checkmodifierValue) {
      checkmodifiers.checkmodifiers = checkmodifierValue || [];
    }

    return checkmodifiers;
  });

  const addCheckAllModifierOption = (checkModifier: any) => {
    setCheckModifierOption({ checkmodifiers: checkModifier });
    localStorage.setItem(
      "checkModifier",
      JSON.stringify({ checkmodifiers: checkModifier }) || "[]"
    );
  };

  const addCheckModifierOption = (checkModifier: any) => {
    setCheckModifierOption({
      ...checkModifierOption,
      checkmodifiers: [...checkModifierOption.checkmodifiers, checkModifier],
    });
    localStorage.setItem(
      "checkModifier",
      JSON.stringify({
        ...checkModifierOption,
        checkmodifiers: [...checkModifierOption.checkmodifiers, checkModifier],
      }) || "[]"
    );
  };

  const deleteCheckModifierOption = (id: any, tipo: number) => {
    // ELIMINAR MODIFICADOR POR ID GRUPO (LOCALSTORAGE)
    if (tipo === 0) {
      const indexCheckModifierOption = checkModifierOption.checkmodifiers
        .map((cm: any) => cm.grupo)
        .indexOf(id);
      checkModifierOption.checkmodifiers.splice(indexCheckModifierOption, 1);
      setCheckModifierOption(checkModifierOption);
      localStorage.setItem(
        "checkModifier",
        JSON.stringify(checkModifierOption) || "[]"
      );
    }
    // ELIMINAR MODIFICADOR POR ID (LOCALSTORAGE)
    if (tipo === 1) {
      const indexCheckModifierOption = checkModifierOption.checkmodifiers
        .map((cm: any) => cm.external_data)
        .indexOf(id);
      checkModifierOption.checkmodifiers.splice(indexCheckModifierOption, 1);
      setCheckModifierOption({ ...checkModifierOption });
      localStorage.setItem(
        "checkModifier",
        JSON.stringify({ ...checkModifierOption }) || "[]"
      );
    }
  };

  //VACIAR OPCIONES DE MODIFICADORES (LOCALSTORAGE)
  const emptyCheckModifierOptions = () => {
    const checkModifierOptions: any[] = [];
    setCheckModifierOption((prevState: any) => ({
      ...prevState,
      checkmodifiers: checkModifierOptions,
    }));
    localStorage.setItem(
      "checkModifier",
      JSON.stringify({
        ...checkModifierOption,
        checkmodifiers: checkModifierOptions,
      }) || "[]"
    );
  };

  // AGREGAR O DISMINUIR CANTIDAD DE MODIFICADORES (LOCALSTORAGE)
  const AddorRemoveCheckQuantityModifierOptions = (
    m: ModifierGroup,
    mo: Item,
    add: boolean,
    objecto: Item,
    itemCount: number
  ) => {
    if (objecto !== undefined || objecto !== null) {
      const max_permitido: number = objecto!.max_quantity * itemCount;
      const min_permitido: number = objecto!.min_quantity * itemCount;
      const max_modifier: number = m.quantity_info.quantity.max_permitted;
      const min_modifier: number = m.quantity_info.quantity.min_permitted;

      let sumSelectedItems = checkModifierOption.checkmodifiers
        .map((chkm: any) => chkm.cantTotal)
        .reduce((prev: any, curr: any) => prev + curr, 0);
      let sumSelectedItems2 = checkModifierOption.checkmodifiers.reduce(
        (prev: any, curr: any) => {
          if (curr.estado === true && curr.isProperty === false)
            prev += curr.cantTotal;
          return prev;
        },
        0
      );
      let sumSelectedItemsModifiers = checkModifierOption.checkmodifiers
        .map((cm: any) => cm.grupo === m.external_data && cm.estado === true)
        .reduce((prev: any, curr: any) => prev + curr, 0);
      let sumSelectedItemsModifiers2 =
        checkModifierOption.checkmodifiers.reduce((prev: any, curr: any) => {
          if (curr.grupo === m.external_data && curr.estado === true)
            prev += curr.cantTotal;
          return prev;
        }, 0);

      if (max_permitido > 0 && min_permitido > 0) {
        if (max_modifier * itemCount > 0 && min_modifier * itemCount > 0) {
          // if(max_permitido > sumSelectedItems){
          //   if(max_modifier > sumSelectedItemsModifiers){
          //   }
          //   else{
          //     console.log('maximo modifier', max_modifier);
          //   }
          // }
          // else{
          //   console.log('maximo gen', max_permitido);
          // }
          if (max_permitido > sumSelectedItems2) {
            if (max_modifier * itemCount > sumSelectedItemsModifiers2) {
              const indexCheckModifierOption =
                checkModifierOption.checkmodifiers
                  .map((cm: any) => cm.external_data)
                  .indexOf(mo.external_data);
              const findModifier = basketModifier.selected_modifier_groups.find(
                (bas: { [x: string]: any }) =>
                  bas["external_data"] === m.external_data
              );
              // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
              checkModifierOption.checkmodifiers.map(
                (chk: any, index: number) => {
                  if (indexCheckModifierOption === index) {
                    if (add) {
                      const price = chk.price / chk.cantTotal / itemCount;
                      chk.cantidad += 1;
                      chk.price = price * itemCount * chk.cantidad;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                    } else {
                      if (chk.cantidad > 1) {
                        const price = chk.price / itemCount / chk.cantidad;
                        chk.cantidad -= 1;
                        // chk.cantTotal = chk.cantidad * itemCount;
                        chk.cantTotal = chk.cantidad;
                        chk.price = price * itemCount * chk.cantidad;
                      } else {
                        checkModifierOption.checkmodifiers.splice(
                          indexCheckModifierOption,
                          1
                        );
                      }
                    }
                  }
                  return {
                    ...chk,
                  };
                }
              );

              setCheckModifierOption({ ...checkModifierOption });
              localStorage.setItem(
                "checkModifier",
                JSON.stringify({ ...checkModifierOption }) || "[]"
              );

              if (findModifier) {
                const indexFindModifier =
                  basketModifier.selected_modifier_groups
                    .map((fm: any) => fm.external_data)
                    .indexOf(m.external_data);
                basketModifier.selected_modifier_groups.length > 0 &&
                  basketModifier.selected_modifier_groups.map(
                    (sm: SelectedModifierGroup, index: number) => {
                      if (indexFindModifier === index) {
                        const indexFindSelectItem = sm.selected_items
                          .map((sm: any) => sm.external_data)
                          .indexOf(mo.external_data);
                        sm.selected_items.length > 1
                          ? sm.selected_items.map((si, indexsi) => {
                              if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  } else {
                                    sm.selected_items.splice(indexsi, 1);
                                  }
                                }
                              }
                              return {
                                ...si,
                              };
                            })
                          : sm.selected_items.map((si, indexsi) => {
                              if (add) {
                                si.quantity += 1;
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                  basketModifier.selected_modifier_groups.splice(
                                    indexFindModifier,
                                    1
                                  );
                                }
                              }

                              return {
                                ...si,
                              };
                            });
                      }
                    }
                  );
                setBasketModifier({ ...basketModifier });
                localStorage.setItem(
                  "basketModifier",
                  JSON.stringify({ ...basketModifier }) || "[]"
                );
              }
            } else {
              console.log("maximo modifier", max_modifier);
              const indexCheckModifierOption =
                checkModifierOption.checkmodifiers
                  .map((cm: any) => cm.external_data)
                  .indexOf(mo.external_data);
              const findModifier = basketModifier.selected_modifier_groups.find(
                (bas: { [x: string]: any }) =>
                  bas["external_data"] === m.external_data
              );
              // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
              checkModifierOption.checkmodifiers.map(
                (chk: any, index: number) => {
                  if (indexCheckModifierOption === index) {
                    if (add) {
                    } else {
                      if (chk.cantidad > 1) {
                        const price = chk.price / itemCount / chk.cantidad;
                        chk.cantidad -= 1;
                        // chk.cantTotal = chk.cantidad * itemCount;
                        chk.cantTotal = chk.cantidad;
                        chk.price = price * itemCount * chk.cantidad;
                      } else {
                        checkModifierOption.checkmodifiers.splice(
                          indexCheckModifierOption,
                          1
                        );
                      }
                    }
                  }
                  return {
                    ...chk,
                  };
                }
              );

              setCheckModifierOption({ ...checkModifierOption });
              localStorage.setItem(
                "checkModifier",
                JSON.stringify({ ...checkModifierOption }) || "[]"
              );

              if (findModifier) {
                const indexFindModifier =
                  basketModifier.selected_modifier_groups
                    .map((fm: any) => fm.external_data)
                    .indexOf(m.external_data);
                basketModifier.selected_modifier_groups.length > 0 &&
                  basketModifier.selected_modifier_groups.map(
                    (sm: SelectedModifierGroup, index: number) => {
                      if (indexFindModifier === index) {
                        const indexFindSelectItem = sm.selected_items
                          .map((sm: any) => sm.external_data)
                          .indexOf(mo.external_data);
                        sm.selected_items.length > 1
                          ? sm.selected_items.map((si, indexsi) => {
                              if (indexFindSelectItem === indexsi) {
                                if (add) {
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  } else {
                                    sm.selected_items.splice(indexsi, 1);
                                  }
                                }
                              }
                              return {
                                ...si,
                              };
                            })
                          : sm.selected_items.map((si, indexsi) => {
                              if (add) {
                                si.quantity += 1;
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                  basketModifier.selected_modifier_groups.splice(
                                    indexFindModifier,
                                    1
                                  );
                                }
                              }

                              return {
                                ...si,
                              };
                            });
                      }
                    }
                  );
                setBasketModifier({ ...basketModifier });
                localStorage.setItem(
                  "basketModifier",
                  JSON.stringify({ ...basketModifier }) || "[]"
                );
              }
            }
          } else {
            // console.log('maxaaaaa');
            const indexCheckModifierOption = checkModifierOption.checkmodifiers
              .map((cm: any) => cm.external_data)
              .indexOf(mo.external_data);
            const findModifier = basketModifier.selected_modifier_groups.find(
              (bas: { [x: string]: any }) =>
                bas["external_data"] === m.external_data
            );
            // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
            checkModifierOption.checkmodifiers.map(
              (chk: any, index: number) => {
                if (indexCheckModifierOption === index) {
                  if (add) {
                  } else {
                    if (chk.cantidad > 1) {
                      const price = chk.price / itemCount / chk.cantidad;
                      chk.cantidad -= 1;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                      chk.price = price * itemCount * chk.cantidad;
                    } else {
                      checkModifierOption.checkmodifiers.splice(
                        indexCheckModifierOption,
                        1
                      );
                    }
                  }
                }
                return {
                  ...chk,
                };
              }
            );

            setCheckModifierOption({ ...checkModifierOption });
            localStorage.setItem(
              "checkModifier",
              JSON.stringify({ ...checkModifierOption }) || "[]"
            );

            if (findModifier) {
              const indexFindModifier = basketModifier.selected_modifier_groups
                .map((fm: any) => fm.external_data)
                .indexOf(m.external_data);
              basketModifier.selected_modifier_groups.length > 0 &&
                basketModifier.selected_modifier_groups.map(
                  (sm: SelectedModifierGroup, index: number) => {
                    if (indexFindModifier === index) {
                      const indexFindSelectItem = sm.selected_items
                        .map((sm: any) => sm.external_data)
                        .indexOf(mo.external_data);
                      sm.selected_items.length > 1
                        ? sm.selected_items.map((si, indexsi) => {
                            if (indexFindSelectItem === indexsi) {
                              if (add) {
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                }
                              }
                            }
                            return {
                              ...si,
                            };
                          })
                        : sm.selected_items.map((si, indexsi) => {
                            if (add) {
                            } else {
                              if (si.quantity > 1) {
                                si.quantity -= 1;
                              } else {
                                sm.selected_items.splice(indexsi, 1);
                                basketModifier.selected_modifier_groups.splice(
                                  indexFindModifier,
                                  1
                                );
                              }
                            }

                            return {
                              ...si,
                            };
                          });
                    }
                  }
                );
              setBasketModifier({ ...basketModifier });
              localStorage.setItem(
                "basketModifier",
                JSON.stringify({ ...basketModifier }) || "[]"
              );
            }
            console.log("maximo gen", max_permitido);
          }
        } else {
          console.log("producto no requerido");
          if (max_permitido > sumSelectedItems) {
            if (max_modifier * itemCount > sumSelectedItemsModifiers) {
              const indexCheckModifierOption =
                checkModifierOption.checkmodifiers
                  .map((cm: any) => cm.external_data)
                  .indexOf(mo.external_data);
              const findModifier = basketModifier.selected_modifier_groups.find(
                (bas: { [x: string]: any }) =>
                  bas["external_data"] === m.external_data
              );
              // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
              checkModifierOption.checkmodifiers.map(
                (chk: any, index: number) => {
                  if (indexCheckModifierOption === index) {
                    if (add) {
                      const price = chk.price / chk.cantTotal;
                      chk.cantidad += 1;
                      chk.price = price * itemCount * chk.cantidad;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                    } else {
                      if (chk.cantidad > 1) {
                        const price = chk.price / itemCount / chk.cantidad;
                        chk.cantidad -= 1;
                        // chk.cantTotal = chk.cantidad * itemCount;
                        chk.cantTotal = chk.cantidad;
                        chk.price = price * itemCount * chk.cantidad;
                      } else {
                        checkModifierOption.checkmodifiers.splice(
                          indexCheckModifierOption,
                          1
                        );
                      }
                    }
                  }
                  return {
                    ...chk,
                  };
                }
              );

              setCheckModifierOption({ ...checkModifierOption });
              localStorage.setItem(
                "checkModifier",
                JSON.stringify({ ...checkModifierOption }) || "[]"
              );

              if (findModifier) {
                const indexFindModifier =
                  basketModifier.selected_modifier_groups
                    .map((fm: any) => fm.external_data)
                    .indexOf(m.external_data);
                basketModifier.selected_modifier_groups.length > 0 &&
                  basketModifier.selected_modifier_groups.map(
                    (sm: SelectedModifierGroup, index: number) => {
                      if (indexFindModifier === index) {
                        const indexFindSelectItem = sm.selected_items
                          .map((sm: any) => sm.external_data)
                          .indexOf(mo.external_data);
                        sm.selected_items.length > 1
                          ? sm.selected_items.map((si, indexsi) => {
                              if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  } else {
                                    sm.selected_items.splice(indexsi, 1);
                                  }
                                }
                              }
                              return {
                                ...si,
                              };
                            })
                          : sm.selected_items.map((si, indexsi) => {
                              if (add) {
                                si.quantity += 1;
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                  basketModifier.selected_modifier_groups.splice(
                                    indexFindModifier,
                                    1
                                  );
                                }
                              }

                              return {
                                ...si,
                              };
                            });
                      }
                    }
                  );
                setBasketModifier({ ...basketModifier });
                localStorage.setItem(
                  "basketModifier",
                  JSON.stringify({ ...basketModifier }) || "[]"
                );
              }
            } else {
              console.log("maximo modifier", max_modifier);
            }
          } else {
            const indexCheckModifierOption = checkModifierOption.checkmodifiers
              .map((cm: any) => cm.external_data)
              .indexOf(mo.external_data);
            const findModifier = basketModifier.selected_modifier_groups.find(
              (bas: { [x: string]: any }) =>
                bas["external_data"] === m.external_data
            );
            // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
            checkModifierOption.checkmodifiers.map(
              (chk: any, index: number) => {
                if (indexCheckModifierOption === index) {
                  if (add) {
                  } else {
                    if (chk.cantidad > 1) {
                      const price = chk.price / itemCount / chk.cantidad;
                      chk.cantidad -= 1;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                      chk.price = price * itemCount * chk.cantidad;
                    } else {
                      checkModifierOption.checkmodifiers.splice(
                        indexCheckModifierOption,
                        1
                      );
                    }
                  }
                }
                return {
                  ...chk,
                };
              }
            );

            setCheckModifierOption({ ...checkModifierOption });
            localStorage.setItem(
              "checkModifier",
              JSON.stringify({ ...checkModifierOption }) || "[]"
            );

            if (findModifier) {
              const indexFindModifier = basketModifier.selected_modifier_groups
                .map((fm: any) => fm.external_data)
                .indexOf(m.external_data);
              basketModifier.selected_modifier_groups.length > 0 &&
                basketModifier.selected_modifier_groups.map(
                  (sm: SelectedModifierGroup, index: number) => {
                    if (indexFindModifier === index) {
                      const indexFindSelectItem = sm.selected_items
                        .map((sm: any) => sm.external_data)
                        .indexOf(mo.external_data);
                      sm.selected_items.length > 1
                        ? sm.selected_items.map((si, indexsi) => {
                            if (indexFindSelectItem === indexsi) {
                              if (add) {
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                }
                              }
                            }
                            return {
                              ...si,
                            };
                          })
                        : sm.selected_items.map((si, indexsi) => {
                            if (add) {
                            } else {
                              if (si.quantity > 1) {
                                si.quantity -= 1;
                              } else {
                                sm.selected_items.splice(indexsi, 1);
                                basketModifier.selected_modifier_groups.splice(
                                  indexFindModifier,
                                  1
                                );
                              }
                            }

                            return {
                              ...si,
                            };
                          });
                    }
                  }
                );
              setBasketModifier({ ...basketModifier });
              localStorage.setItem(
                "basketModifier",
                JSON.stringify({ ...basketModifier }) || "[]"
              );
            }
            console.log("maximo gen", max_permitido);
          }
        }
      } else {
        console.log("producto normal");
      }
    }
  };

  const AddorRemoveTotalQuantityModifierOptions = (
    ob: Item,
    m: any,
    add: boolean,
    itemCount: number
  ) => {
    const max_permitido: number = ob!.max_quantity * itemCount;
    const min_permitido: number = ob!.min_quantity * itemCount;
    console.log("max general", max_permitido);
    console.log("min general", min_permitido);

    m !== undefined &&
      ob.modifier_group_ids.ids !== undefined &&
      m
        .filter((mod: any) =>
          ob.modifier_group_ids.ids.some(
            (mg) => mg.external_data === mod.external_data
          )
        )
        .sort((a: any, b: any) =>
          a.external_data < b.external_data
            ? -1
            : a.external_data > b.external_data
            ? 1
            : 0
        )
        .map(
          (modifier: any, index: number) => {
            if (max_permitido > 0 && min_permitido > 0) {
              const max_modifier: number =
                modifier.quantity_info.quantity.max_permitted;
              const min_modifier: number =
                modifier.quantity_info.quantity.min_permitted;
              if (max_modifier > 0 && min_modifier > 0) {
                // if(checkModifierOption.checkmodifiers.length > 0){
                //   checkModifierOption.checkmodifiers.map((chk: any, index:number) => {
                //     if (chk.grupo === modifier.external_data) {
                //       if (add) {
                //         const price = chk.price / chk.cantTotal;
                //         chk.cantidad += 1;
                //         chk.price = price * itemCount;
                //         chk.cantTotal += 1;
                //       } else {
                //         const price = chk.price / chk.cantTotal;
                //         chk.cantidad -= 1;
                //         chk.cantTotal -= 1;
                //         chk.price = price * itemCount;
                //       }
                //     }
                //     return {
                //       ...chk,
                //     };
                //   });
                //   setCheckModifierOption({ ...checkModifierOption});
                //   localStorage.setItem("checkModifier", JSON.stringify({ ...checkModifierOption}) || '[]');
                //   basketModifier.selected_modifier_groups.length > 0 && basketModifier.selected_modifier_groups.map((sm: SelectedModifierGroup, index: number) =>
                //   {
                //     if(sm.external_data === modifier.external_data){
                //       // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                //       (sm.selected_items.length > 1) ?
                //         sm.selected_items.map((si, indexsi) => {
                //           // if (indexFindSelectItem === indexsi) {
                //             if (add) {
                //               si.quantity += 1;
                //             } else {
                //               if (si.quantity > 1) {
                //                 si.quantity -= 1;
                //               }
                //               // else{
                //               //   sm.selected_items.splice(indexsi, 1);
                //               // }
                //             }
                //           // }
                //           return {
                //             ...si,
                //           };
                //         })
                //       :
                //         sm.selected_items.map((si, indexsi) => {
                //             if (add) {
                //               si.quantity += 1;
                //             } else {
                //               if (si.quantity > 1) {
                //                 si.quantity -= 1;
                //               }
                //               // else{
                //               //   sm.selected_items.splice(indexsi, 1);
                //               //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                //               // }
                //             }
                //           return {
                //             ...si,
                //           };
                //         })
                //     }
                //   }
                // )
                // setBasketModifier({ ...basketModifier});
                // localStorage.setItem("basketModifier", JSON.stringify({ ...basketModifier}) || '[]');
                // }
              } else {
                if (checkModifierOption.checkmodifiers.length > 0) {
                  checkModifierOption.checkmodifiers.map(
                    (chk: any, index: number) => {
                      if (chk.grupo === modifier.external_data) {
                        if (add) {
                          const itemCountbefore = itemCount - 1;
                          const price = chk.price / itemCountbefore;
                          chk.price = price * itemCount * chk.cantidad;
                          // chk.cantTotal = chk.cantidad * itemCount;
                          chk.cantTotal = chk.cantidad;
                        } else {
                          const itemCountbefore = itemCount + 1;
                          const price = chk.price / itemCountbefore;
                          chk.price = price * itemCount * chk.cantidad;
                          // chk.cantTotal = chk.cantidad * itemCount;
                          chk.cantTotal = chk.cantidad;
                        }
                      }

                      return {
                        ...chk,
                      };
                    }
                  );

                  setCheckModifierOption({ ...checkModifierOption });
                  localStorage.setItem(
                    "checkModifier",
                    JSON.stringify({ ...checkModifierOption }) || "[]"
                  );

                  basketModifier.selected_modifier_groups.length > 0 &&
                    basketModifier.selected_modifier_groups.map(
                      (sm: SelectedModifierGroup, index: number) => {
                        if (sm.external_data === modifier.external_data) {
                          // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                          sm.selected_items.length > 1
                            ? sm.selected_items.map((si, indexsi) => {
                                // if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  // const itemCountbefore = itemCount - 1;
                                  // const price = si.price?.total_price / itemCountbefore;
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  // }
                                }
                                // }
                                return {
                                  ...si,
                                };
                              })
                            : sm.selected_items.map((si, indexsi) => {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                                  // }
                                }

                                return {
                                  ...si,
                                };
                              });
                        }
                      }
                    );
                  setBasketModifier({ ...basketModifier });
                  localStorage.setItem(
                    "basketModifier",
                    JSON.stringify({ ...basketModifier }) || "[]"
                  );
                }
              }
            } else {
              // console.log('producto normal');
              const max_modifier: number =
                modifier.quantity_info.quantity.max_permitted;
              const min_modifier: number =
                modifier.quantity_info.quantity.min_permitted;
              if (max_modifier > 0 && min_modifier > 0) {
                if (checkModifierOption.checkmodifiers.length > 0) {
                  checkModifierOption.checkmodifiers.map(
                    (chk: any, index: number) => {
                      if (chk.grupo === modifier.external_data) {
                        if (add) {
                          const price = chk.price / chk.cantTotal;
                          chk.cantidad += 1;
                          chk.price = price * itemCount;
                          chk.cantTotal += 1;
                        } else {
                          const price = chk.price / chk.cantTotal;
                          chk.cantidad -= 1;
                          chk.cantTotal -= 1;
                          chk.price = price * itemCount;
                        }
                      }
                      return {
                        ...chk,
                      };
                    }
                  );

                  setCheckModifierOption({ ...checkModifierOption });
                  localStorage.setItem(
                    "checkModifier",
                    JSON.stringify({ ...checkModifierOption }) || "[]"
                  );

                  basketModifier.selected_modifier_groups.length > 0 &&
                    basketModifier.selected_modifier_groups.map(
                      (sm: SelectedModifierGroup, index: number) => {
                        if (sm.external_data === modifier.external_data) {
                          // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                          sm.selected_items.length > 1
                            ? sm.selected_items.map((si, indexsi) => {
                                // if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  // }
                                }
                                // }
                                return {
                                  ...si,
                                };
                              })
                            : sm.selected_items.map((si, indexsi) => {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                                  // }
                                }

                                return {
                                  ...si,
                                };
                              });
                        }
                      }
                    );
                  setBasketModifier({ ...basketModifier });
                  localStorage.setItem(
                    "basketModifier",
                    JSON.stringify({ ...basketModifier }) || "[]"
                  );
                }
              } else {
                if (checkModifierOption.checkmodifiers.length > 0) {
                  checkModifierOption.checkmodifiers.map(
                    (chk: any, index: number) => {
                      if (chk.grupo === modifier.external_data) {
                        if (add) {
                          const price = chk.price / chk.cantTotal;
                          chk.price = price * itemCount * chk.cantidad;
                          chk.cantTotal = chk.cantidad * itemCount;
                        } else {
                          const price = chk.price / chk.cantTotal;
                          chk.price = price * itemCount * chk.cantidad;
                          chk.cantTotal = chk.cantidad * itemCount;
                        }
                      }

                      return {
                        ...chk,
                      };
                    }
                  );

                  setCheckModifierOption({ ...checkModifierOption });
                  localStorage.setItem(
                    "checkModifier",
                    JSON.stringify({ ...checkModifierOption }) || "[]"
                  );

                  basketModifier.selected_modifier_groups.length > 0 &&
                    basketModifier.selected_modifier_groups.map(
                      (sm: SelectedModifierGroup, index: number) => {
                        if (sm.external_data === modifier.external_data) {
                          // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                          sm.selected_items.length > 1
                            ? sm.selected_items.map((si, indexsi) => {
                                // if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  // }
                                }
                                // }
                                return {
                                  ...si,
                                };
                              })
                            : sm.selected_items.map((si, indexsi) => {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                                  // }
                                }

                                return {
                                  ...si,
                                };
                              });
                        }
                      }
                    );
                  setBasketModifier({ ...basketModifier });
                  localStorage.setItem(
                    "basketModifier",
                    JSON.stringify({ ...basketModifier }) || "[]"
                  );
                }
              }
            }
          }
          // console.log(modifier)
        );
  };

  // AGREGAR O DISMINUIR CANTIDAD DE MODIFICADORES EN MODAL EDITAR (LOCALSTORAGE)
  const AddorRemoveCheckQuantityModifierOptionsUpdate = (
    m: ModifierGroup,
    mo: Item,
    add: boolean,
    objecto: Item,
    itemCount: number
  ) => {
    if (objecto !== undefined || objecto !== null) {
      const max_permitido: number = objecto!.max_quantity * itemCount;
      const min_permitido: number = objecto!.min_quantity * itemCount;
      const max_modifier: number = m.quantity_info.quantity.max_permitted;
      const min_modifier: number = m.quantity_info.quantity.min_permitted;

      let sumSelectedItems = checkModifierOption.checkmodifiers
        .map((chkm: any) => chkm.cantTotal)
        .reduce((prev: any, curr: any) => prev + curr, 0);
      let sumSelectedItems2 = checkModifierOption.checkmodifiers.reduce(
        (prev: any, curr: any) => {
          if (curr.estado === true && curr.isProperty === false)
            prev += curr.cantTotal;
          return prev;
        },
        0
      );
      let sumSelectedItemsModifiers = checkModifierOption.checkmodifiers
        .map((cm: any) => cm.grupo === m.external_data && cm.estado === true)
        .reduce((prev: any, curr: any) => prev + curr, 0);
      let sumSelectedItemsModifiers2 =
        checkModifierOption.checkmodifiers.reduce((prev: any, curr: any) => {
          if (curr.grupo === m.external_data && curr.estado === true)
            prev += curr.cantTotal;
          return prev;
        }, 0);

      if (max_permitido > 0 && min_permitido > 0) {
        if (max_modifier * itemCount > 0 && min_modifier * itemCount > 0) {
          // if(max_permitido > sumSelectedItems){
          //   if(max_modifier > sumSelectedItemsModifiers){
          //   }
          //   else{
          //     console.log('maximo modifier', max_modifier);
          //   }
          // }
          // else{
          //   console.log('maximo gen', max_permitido);
          // }
          if (max_permitido > sumSelectedItems2) {
            if (max_modifier * itemCount > sumSelectedItemsModifiers2) {
              const indexCheckModifierOption =
                checkModifierOption.checkmodifiers
                  .map((cm: any) => cm.external_data)
                  .indexOf(mo.external_data);
              const findModifier =
                basketModifierEditar.selected_modifier_groups.find(
                  (bas: { [x: string]: any }) =>
                    bas["external_data"] === m.external_data
                );
              // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
              checkModifierOption.checkmodifiers.map(
                (chk: any, index: number) => {
                  if (indexCheckModifierOption === index) {
                    if (add) {
                      const price = chk.price / chk.cantTotal / itemCount;
                      chk.cantidad += 1;
                      chk.price = price * itemCount * chk.cantidad;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                    } else {
                      if (chk.cantidad > 1) {
                        const price = chk.price / itemCount / chk.cantidad;
                        chk.cantidad -= 1;
                        // chk.cantTotal = chk.cantidad * itemCount;
                        chk.cantTotal = chk.cantidad;
                        chk.price = price * itemCount * chk.cantidad;
                      } else {
                        checkModifierOption.checkmodifiers.splice(
                          indexCheckModifierOption,
                          1
                        );
                      }
                    }
                  }
                  return {
                    ...chk,
                  };
                }
              );

              setCheckModifierOption({ ...checkModifierOption });
              localStorage.setItem(
                "checkModifier",
                JSON.stringify({ ...checkModifierOption }) || "[]"
              );

              if (findModifier) {
                const indexFindModifier =
                  basketModifierEditar.selected_modifier_groups
                    .map((fm: any) => fm.external_data)
                    .indexOf(m.external_data);
                basketModifierEditar.selected_modifier_groups.length > 0 &&
                  basketModifierEditar.selected_modifier_groups.map(
                    (sm: SelectedModifierGroup, index: number) => {
                      if (indexFindModifier === index) {
                        const indexFindSelectItem = sm.selected_items
                          .map((sm: any) => sm.external_data)
                          .indexOf(mo.external_data);
                        sm.selected_items.length > 1
                          ? sm.selected_items.map((si, indexsi) => {
                              if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  } else {
                                    sm.selected_items.splice(indexsi, 1);
                                  }
                                }
                              }
                              return {
                                ...si,
                              };
                            })
                          : sm.selected_items.map((si, indexsi) => {
                              if (add) {
                                si.quantity += 1;
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                  basketModifierEditar.selected_modifier_groups.splice(
                                    indexFindModifier,
                                    1
                                  );
                                }
                              }

                              return {
                                ...si,
                              };
                            });
                      }
                    }
                  );
                setBasketModifierEditar({ ...basketModifierEditar });
                localStorage.setItem(
                  "basketModifierEditar",
                  JSON.stringify({ ...basketModifierEditar }) || "[]"
                );
              }
            } else {
              console.log("maximo modifier", max_modifier);
              const indexCheckModifierOption =
                checkModifierOption.checkmodifiers
                  .map((cm: any) => cm.external_data)
                  .indexOf(mo.external_data);
              const findModifier =
                basketModifierEditar.selected_modifier_groups.find(
                  (bas: { [x: string]: any }) =>
                    bas["external_data"] === m.external_data
                );
              // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
              checkModifierOption.checkmodifiers.map(
                (chk: any, index: number) => {
                  if (indexCheckModifierOption === index) {
                    if (add) {
                    } else {
                      if (chk.cantidad > 1) {
                        const price = chk.price / itemCount / chk.cantidad;
                        chk.cantidad -= 1;
                        // chk.cantTotal = chk.cantidad * itemCount;
                        chk.cantTotal = chk.cantidad;
                        chk.price = price * itemCount * chk.cantidad;
                      } else {
                        checkModifierOption.checkmodifiers.splice(
                          indexCheckModifierOption,
                          1
                        );
                      }
                    }
                  }
                  return {
                    ...chk,
                  };
                }
              );

              setCheckModifierOption({ ...checkModifierOption });
              localStorage.setItem(
                "checkModifier",
                JSON.stringify({ ...checkModifierOption }) || "[]"
              );

              if (findModifier) {
                const indexFindModifier =
                  basketModifierEditar.selected_modifier_groups
                    .map((fm: any) => fm.external_data)
                    .indexOf(m.external_data);
                basketModifierEditar.selected_modifier_groups.length > 0 &&
                  basketModifierEditar.selected_modifier_groups.map(
                    (sm: SelectedModifierGroup, index: number) => {
                      if (indexFindModifier === index) {
                        const indexFindSelectItem = sm.selected_items
                          .map((sm: any) => sm.external_data)
                          .indexOf(mo.external_data);
                        sm.selected_items.length > 1
                          ? sm.selected_items.map((si, indexsi) => {
                              if (indexFindSelectItem === indexsi) {
                                if (add) {
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  } else {
                                    sm.selected_items.splice(indexsi, 1);
                                  }
                                }
                              }
                              return {
                                ...si,
                              };
                            })
                          : sm.selected_items.map((si, indexsi) => {
                              if (add) {
                                si.quantity += 1;
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                  basketModifierEditar.selected_modifier_groups.splice(
                                    indexFindModifier,
                                    1
                                  );
                                }
                              }

                              return {
                                ...si,
                              };
                            });
                      }
                    }
                  );
                setBasketModifierEditar({ ...basketModifierEditar });
                localStorage.setItem(
                  "basketModifierEditar",
                  JSON.stringify({ ...basketModifierEditar }) || "[]"
                );
              }
            }
          } else {
            // console.log('maxaaaaa');
            const indexCheckModifierOption = checkModifierOption.checkmodifiers
              .map((cm: any) => cm.external_data)
              .indexOf(mo.external_data);
            const findModifier =
              basketModifierEditar.selected_modifier_groups.find(
                (bas: { [x: string]: any }) =>
                  bas["external_data"] === m.external_data
              );
            // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
            checkModifierOption.checkmodifiers.map(
              (chk: any, index: number) => {
                if (indexCheckModifierOption === index) {
                  if (add) {
                  } else {
                    if (chk.cantidad > 1) {
                      const price = chk.price / itemCount / chk.cantidad;
                      chk.cantidad -= 1;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                      chk.price = price * itemCount * chk.cantidad;
                    } else {
                      checkModifierOption.checkmodifiers.splice(
                        indexCheckModifierOption,
                        1
                      );
                    }
                  }
                }
                return {
                  ...chk,
                };
              }
            );

            setCheckModifierOption({ ...checkModifierOption });
            localStorage.setItem(
              "checkModifier",
              JSON.stringify({ ...checkModifierOption }) || "[]"
            );

            if (findModifier) {
              const indexFindModifier =
                basketModifierEditar.selected_modifier_groups
                  .map((fm: any) => fm.external_data)
                  .indexOf(m.external_data);
              basketModifierEditar.selected_modifier_groups.length > 0 &&
                basketModifierEditar.selected_modifier_groups.map(
                  (sm: SelectedModifierGroup, index: number) => {
                    if (indexFindModifier === index) {
                      const indexFindSelectItem = sm.selected_items
                        .map((sm: any) => sm.external_data)
                        .indexOf(mo.external_data);
                      sm.selected_items.length > 1
                        ? sm.selected_items.map((si, indexsi) => {
                            if (indexFindSelectItem === indexsi) {
                              if (add) {
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                }
                              }
                            }
                            return {
                              ...si,
                            };
                          })
                        : sm.selected_items.map((si, indexsi) => {
                            if (add) {
                            } else {
                              if (si.quantity > 1) {
                                si.quantity -= 1;
                              } else {
                                sm.selected_items.splice(indexsi, 1);
                                basketModifierEditar.selected_modifier_groups.splice(
                                  indexFindModifier,
                                  1
                                );
                              }
                            }

                            return {
                              ...si,
                            };
                          });
                    }
                  }
                );
              setBasketModifierEditar({ ...basketModifierEditar });
              localStorage.setItem(
                "basketModifierEditar",
                JSON.stringify({ ...basketModifierEditar }) || "[]"
              );
            }
            console.log("maximo gen", max_permitido);
          }
        } else {
          console.log("producto no requerido");
          if (max_permitido > sumSelectedItems) {
            if (max_modifier * itemCount > sumSelectedItemsModifiers) {
              const indexCheckModifierOption =
                checkModifierOption.checkmodifiers
                  .map((cm: any) => cm.external_data)
                  .indexOf(mo.external_data);
              const findModifier =
                basketModifierEditar.selected_modifier_groups.find(
                  (bas: { [x: string]: any }) =>
                    bas["external_data"] === m.external_data
                );
              // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
              checkModifierOption.checkmodifiers.map(
                (chk: any, index: number) => {
                  if (indexCheckModifierOption === index) {
                    if (add) {
                      const price = chk.price / chk.cantTotal;
                      chk.cantidad += 1;
                      chk.price = price * itemCount * chk.cantidad;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                    } else {
                      if (chk.cantidad > 1) {
                        const price = chk.price / itemCount / chk.cantidad;
                        chk.cantidad -= 1;
                        // chk.cantTotal = chk.cantidad * itemCount;
                        chk.cantTotal = chk.cantidad;
                        chk.price = price * itemCount * chk.cantidad;
                      } else {
                        checkModifierOption.checkmodifiers.splice(
                          indexCheckModifierOption,
                          1
                        );
                      }
                    }
                  }
                  return {
                    ...chk,
                  };
                }
              );

              setCheckModifierOption({ ...checkModifierOption });
              localStorage.setItem(
                "checkModifier",
                JSON.stringify({ ...checkModifierOption }) || "[]"
              );

              if (findModifier) {
                const indexFindModifier =
                  basketModifierEditar.selected_modifier_groups
                    .map((fm: any) => fm.external_data)
                    .indexOf(m.external_data);
                basketModifierEditar.selected_modifier_groups.length > 0 &&
                  basketModifierEditar.selected_modifier_groups.map(
                    (sm: SelectedModifierGroup, index: number) => {
                      if (indexFindModifier === index) {
                        const indexFindSelectItem = sm.selected_items
                          .map((sm: any) => sm.external_data)
                          .indexOf(mo.external_data);
                        sm.selected_items.length > 1
                          ? sm.selected_items.map((si, indexsi) => {
                              if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  } else {
                                    sm.selected_items.splice(indexsi, 1);
                                  }
                                }
                              }
                              return {
                                ...si,
                              };
                            })
                          : sm.selected_items.map((si, indexsi) => {
                              if (add) {
                                si.quantity += 1;
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                  basketModifierEditar.selected_modifier_groups.splice(
                                    indexFindModifier,
                                    1
                                  );
                                }
                              }

                              return {
                                ...si,
                              };
                            });
                      }
                    }
                  );
                setBasketModifierEditar({ ...basketModifierEditar });
                localStorage.setItem(
                  "basketModifierEditar",
                  JSON.stringify({ ...basketModifierEditar }) || "[]"
                );
              }
            } else {
              console.log("maximo modifier", max_modifier);
            }
          } else {
            const indexCheckModifierOption = checkModifierOption.checkmodifiers
              .map((cm: any) => cm.external_data)
              .indexOf(mo.external_data);
            const findModifier =
              basketModifierEditar.selected_modifier_groups.find(
                (bas: { [x: string]: any }) =>
                  bas["external_data"] === m.external_data
              );
            // const indexFindModifier = basketModifier.selected_items.map((fm:any) => fm.external_data).indexOf(mo.external_data);
            checkModifierOption.checkmodifiers.map(
              (chk: any, index: number) => {
                if (indexCheckModifierOption === index) {
                  if (add) {
                  } else {
                    if (chk.cantidad > 1) {
                      const price = chk.price / itemCount / chk.cantidad;
                      chk.cantidad -= 1;
                      // chk.cantTotal = chk.cantidad * itemCount;
                      chk.cantTotal = chk.cantidad;
                      chk.price = price * itemCount * chk.cantidad;
                    } else {
                      checkModifierOption.checkmodifiers.splice(
                        indexCheckModifierOption,
                        1
                      );
                    }
                  }
                }
                return {
                  ...chk,
                };
              }
            );

            setCheckModifierOption({ ...checkModifierOption });
            localStorage.setItem(
              "checkModifier",
              JSON.stringify({ ...checkModifierOption }) || "[]"
            );

            if (findModifier) {
              const indexFindModifier =
                basketModifierEditar.selected_modifier_groups
                  .map((fm: any) => fm.external_data)
                  .indexOf(m.external_data);
              basketModifierEditar.selected_modifier_groups.length > 0 &&
                basketModifierEditar.selected_modifier_groups.map(
                  (sm: SelectedModifierGroup, index: number) => {
                    if (indexFindModifier === index) {
                      const indexFindSelectItem = sm.selected_items
                        .map((sm: any) => sm.external_data)
                        .indexOf(mo.external_data);
                      sm.selected_items.length > 1
                        ? sm.selected_items.map((si, indexsi) => {
                            if (indexFindSelectItem === indexsi) {
                              if (add) {
                              } else {
                                if (si.quantity > 1) {
                                  si.quantity -= 1;
                                } else {
                                  sm.selected_items.splice(indexsi, 1);
                                }
                              }
                            }
                            return {
                              ...si,
                            };
                          })
                        : sm.selected_items.map((si, indexsi) => {
                            if (add) {
                            } else {
                              if (si.quantity > 1) {
                                si.quantity -= 1;
                              } else {
                                sm.selected_items.splice(indexsi, 1);
                                basketModifierEditar.selected_modifier_groups.splice(
                                  indexFindModifier,
                                  1
                                );
                              }
                            }

                            return {
                              ...si,
                            };
                          });
                    }
                  }
                );
              setBasketModifierEditar({ ...basketModifierEditar });
              localStorage.setItem(
                "basketModifierEditar",
                JSON.stringify({ ...basketModifierEditar }) || "[]"
              );
            }
            console.log("maximo gen", max_permitido);
          }
        }
      } else {
        console.log("producto normal");
      }
    }
  };

  const AddorRemoveTotalQuantityModifierOptionsUpdate = (
    ob: Item,
    m: any,
    add: boolean,
    itemCount: number
  ) => {
    // console.log(ob);
    // console.log(m);
    const max_permitido: number = ob!.max_quantity * itemCount;
    const min_permitido: number = ob!.min_quantity * itemCount;
    console.log("max general", max_permitido);
    console.log("min general", min_permitido);

    m !== undefined &&
      ob.modifier_group_ids.ids !== undefined &&
      m
        .filter((mod: any) =>
          ob.modifier_group_ids.ids.some(
            (mg) => mg.external_data === mod.external_data
          )
        )
        .sort((a: any, b: any) =>
          a.external_data < b.external_data
            ? -1
            : a.external_data > b.external_data
            ? 1
            : 0
        )
        .map(
          (modifier: any, index: number) => {
            if (max_permitido > 0 && min_permitido > 0) {
              const max_modifier: number =
                modifier.quantity_info.quantity.max_permitted;
              const min_modifier: number =
                modifier.quantity_info.quantity.min_permitted;
              if (max_modifier > 0 && min_modifier > 0) {
                // if(checkModifierOption.checkmodifiers.length > 0){
                //   checkModifierOption.checkmodifiers.map((chk: any, index:number) => {
                //     if (chk.grupo === modifier.external_data) {
                //       if (add) {
                //         const price = chk.price / chk.cantTotal;
                //         chk.cantidad += 1;
                //         chk.price = price * itemCount;
                //         chk.cantTotal += 1;
                //       } else {
                //         const price = chk.price / chk.cantTotal;
                //         chk.cantidad -= 1;
                //         chk.cantTotal -= 1;
                //         chk.price = price * itemCount;
                //       }
                //     }
                //     return {
                //       ...chk,
                //     };
                //   });
                //   setCheckModifierOption({ ...checkModifierOption});
                //   localStorage.setItem("checkModifier", JSON.stringify({ ...checkModifierOption}) || '[]');
                //   basketModifier.selected_modifier_groups.length > 0 && basketModifier.selected_modifier_groups.map((sm: SelectedModifierGroup, index: number) =>
                //   {
                //     if(sm.external_data === modifier.external_data){
                //       // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                //       (sm.selected_items.length > 1) ?
                //         sm.selected_items.map((si, indexsi) => {
                //           // if (indexFindSelectItem === indexsi) {
                //             if (add) {
                //               si.quantity += 1;
                //             } else {
                //               if (si.quantity > 1) {
                //                 si.quantity -= 1;
                //               }
                //               // else{
                //               //   sm.selected_items.splice(indexsi, 1);
                //               // }
                //             }
                //           // }
                //           return {
                //             ...si,
                //           };
                //         })
                //       :
                //         sm.selected_items.map((si, indexsi) => {
                //             if (add) {
                //               si.quantity += 1;
                //             } else {
                //               if (si.quantity > 1) {
                //                 si.quantity -= 1;
                //               }
                //               // else{
                //               //   sm.selected_items.splice(indexsi, 1);
                //               //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                //               // }
                //             }
                //           return {
                //             ...si,
                //           };
                //         })
                //     }
                //   }
                // )
                // setBasketModifier({ ...basketModifier});
                // localStorage.setItem("basketModifier", JSON.stringify({ ...basketModifier}) || '[]');
                // }
              } else {
                if (checkModifierOption.checkmodifiers.length > 0) {
                  checkModifierOption.checkmodifiers.map(
                    (chk: any, index: number) => {
                      if (chk.grupo === modifier.external_data) {
                        if (add) {
                          const itemCountbefore = itemCount - 1;
                          const price = chk.price / itemCountbefore;
                          chk.price = price * itemCount * chk.cantidad;
                          // chk.cantTotal = chk.cantidad * itemCount;
                          chk.cantTotal = chk.cantidad;
                        } else {
                          const itemCountbefore = itemCount + 1;
                          const price = chk.price / itemCountbefore;
                          chk.price = price * itemCount * chk.cantidad;
                          // chk.cantTotal = chk.cantidad * itemCount;
                          chk.cantTotal = chk.cantidad;
                        }
                      }

                      return {
                        ...chk,
                      };
                    }
                  );

                  setCheckModifierOption({ ...checkModifierOption });
                  localStorage.setItem(
                    "checkModifier",
                    JSON.stringify({ ...checkModifierOption }) || "[]"
                  );

                  basketModifierEditar.selected_modifier_groups.length > 0 &&
                    basketModifierEditar.selected_modifier_groups.map(
                      (sm: SelectedModifierGroup, index: number) => {
                        if (sm.external_data === modifier.external_data) {
                          // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                          sm.selected_items.length > 1
                            ? sm.selected_items.map((si, indexsi) => {
                                // if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  // const itemCountbefore = itemCount - 1;
                                  // const price = si.price?.total_price / itemCountbefore;
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  // }
                                }
                                // }
                                return {
                                  ...si,
                                };
                              })
                            : sm.selected_items.map((si, indexsi) => {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                                  // }
                                }

                                return {
                                  ...si,
                                };
                              });
                        }
                      }
                    );
                  setBasketModifier({ ...basketModifierEditar });
                  localStorage.setItem(
                    "basketModifierEditar",
                    JSON.stringify({ ...basketModifierEditar }) || "[]"
                  );
                }
              }
            } else {
              // console.log('producto normal');
              const max_modifier: number =
                modifier.quantity_info.quantity.max_permitted;
              const min_modifier: number =
                modifier.quantity_info.quantity.min_permitted;
              if (max_modifier > 0 && min_modifier > 0) {
                if (checkModifierOption.checkmodifiers.length > 0) {
                  checkModifierOption.checkmodifiers.map(
                    (chk: any, index: number) => {
                      if (chk.grupo === modifier.external_data) {
                        if (add) {
                          const price = chk.price / chk.cantTotal;
                          chk.cantidad += 1;
                          chk.price = price * itemCount;
                          chk.cantTotal += 1;
                        } else {
                          const price = chk.price / chk.cantTotal;
                          chk.cantidad -= 1;
                          chk.cantTotal -= 1;
                          chk.price = price * itemCount;
                        }
                      }
                      return {
                        ...chk,
                      };
                    }
                  );

                  setCheckModifierOption({ ...checkModifierOption });
                  localStorage.setItem(
                    "checkModifier",
                    JSON.stringify({ ...checkModifierOption }) || "[]"
                  );

                  basketModifierEditar.selected_modifier_groups.length > 0 &&
                    basketModifierEditar.selected_modifier_groups.map(
                      (sm: SelectedModifierGroup, index: number) => {
                        if (sm.external_data === modifier.external_data) {
                          // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                          sm.selected_items.length > 1
                            ? sm.selected_items.map((si, indexsi) => {
                                // if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  // }
                                }
                                // }
                                return {
                                  ...si,
                                };
                              })
                            : sm.selected_items.map((si, indexsi) => {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                                  // }
                                }

                                return {
                                  ...si,
                                };
                              });
                        }
                      }
                    );
                  setBasketModifier({ ...basketModifierEditar });
                  localStorage.setItem(
                    "basketModifierEditar",
                    JSON.stringify({ ...basketModifierEditar }) || "[]"
                  );
                }
              } else {
                if (checkModifierOption.checkmodifiers.length > 0) {
                  checkModifierOption.checkmodifiers.map(
                    (chk: any, index: number) => {
                      if (chk.grupo === modifier.external_data) {
                        if (add) {
                          const price = chk.price / chk.cantTotal;
                          chk.price = price * itemCount * chk.cantidad;
                          chk.cantTotal = chk.cantidad * itemCount;
                        } else {
                          const price = chk.price / chk.cantTotal;
                          chk.price = price * itemCount * chk.cantidad;
                          chk.cantTotal = chk.cantidad * itemCount;
                        }
                      }

                      return {
                        ...chk,
                      };
                    }
                  );

                  setCheckModifierOption({ ...checkModifierOption });
                  localStorage.setItem(
                    "checkModifier",
                    JSON.stringify({ ...checkModifierOption }) || "[]"
                  );

                  basketModifierEditar.selected_modifier_groups.length > 0 &&
                    basketModifierEditar.selected_modifier_groups.map(
                      (sm: SelectedModifierGroup, index: number) => {
                        if (sm.external_data === modifier.external_data) {
                          // const indexFindSelectItem = sm.selected_items.map((sm:any) => sm.external_data).indexOf(mo.external_data);
                          sm.selected_items.length > 1
                            ? sm.selected_items.map((si, indexsi) => {
                                // if (indexFindSelectItem === indexsi) {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  // }
                                }
                                // }
                                return {
                                  ...si,
                                };
                              })
                            : sm.selected_items.map((si, indexsi) => {
                                if (add) {
                                  si.quantity += 1;
                                } else {
                                  if (si.quantity > 1) {
                                    si.quantity -= 1;
                                  }
                                  // else{
                                  //   sm.selected_items.splice(indexsi, 1);
                                  //   basketModifier.selected_modifier_groups.splice(indexFindModifier, 1);
                                  // }
                                }

                                return {
                                  ...si,
                                };
                              });
                        }
                      }
                    );
                  setBasketModifier({ ...basketModifierEditar });
                  localStorage.setItem(
                    "basketModifierEditar",
                    JSON.stringify({ ...basketModifierEditar }) || "[]"
                  );
                }
              }
            }
          }
          // console.log(modifier)
        );
  };

  // const deleteElementFromBasketModifier = (id: string) => {
  //   const productsModifier: SelectedModifierGroup[] = basketModifier.filter((bas: any) => bas.id !== id);
  //   setBasketModifier(prevState => ({ ...prevState, selected_items: products }));
  //   localStorage.setItem("basketModifier", JSON.stringify(products) || '[]');
  // };

  // LIMPIAR EL CARRITO
  const handleCart = () => {
    localStorage.removeItem("basketOrder");
    localStorage.removeItem("order_id");
    localStorage.removeItem("basketModifier");
    localStorage.removeItem("propina");
    localStorage.removeItem("checkModifier");
  };

  const idStore = (idStore: any) => {
    localStorage.setItem("idstore", idStore);
    setStore(idStore!);
  };

  const idMesa = (idMesa: any) => {
    localStorage.setItem("idmesa", idMesa);
    setMesa(idMesa!);
  };

  const idNameMesa = (idNameMesa: any) => {
    localStorage.setItem("name_mesa", idNameMesa);
    setNameMesa(idNameMesa!);
  };

  const idEstado = (estado1: boolean) => {
    setEstado(estado1);
  };

  const idEstadoModal = (estado1: boolean, id: number) => {
    setEstadoModal(estado1);
  };

  const idItemCount = (numero: number) => {
    setItemCount(numero);
  };

  const idPrice = (numero: number) => {
    setPrice(numero);
  };

  const idPriceTotal = (numero: number) => {
    setPriceTotal(numero);
  };

  const idDigitalLetter = (idDigitalLetter: any) => {
    localStorage.setItem("digital_letter", JSON.stringify(idDigitalLetter));
    setDigitalLetter(idDigitalLetter);
  };

  const idOptionsChannel = (idOptionsChannel: any) => {
    localStorage.setItem("options_channel", JSON.stringify(idOptionsChannel));
    setOptionsChannel(idOptionsChannel);
  };

  const idIsCart = (idIsCart: any) => {
    localStorage.setItem("is_cart", idIsCart);
    setIsCart(idIsCart);
  };

  const idIsLogin = (idIsLogin: any) => {
    localStorage.setItem("is_login", idIsLogin);
    setIsLogin(idIsLogin);
  };

  const idIsChannelSelect = (idIsChannelSelect: any) => {
    localStorage.setItem("channelSelected", JSON.stringify(idIsChannelSelect));
    setChannelSelect(idIsChannelSelect);
  };

  const handleLocalStorage = (storeData: any, id: string) => {
    localStorage.clear();
    localStorage.setItem("storeData", JSON.stringify(storeData));
    localStorage.setItem("infoClient", storeData.data.data.name);
    localStorage.setItem("imgPath", storeData.data.data.raw_hero_url);
    localStorage.setItem("idstore", storeData.data.data.id);
    localStorage.setItem("userName",name);
    

    // localStorage.setItem('digital_letter', JSON.stringify(storeData?.data.data.digital_letter))
    idDigitalLetter(storeData?.data.data.digital_letter);
    localStorage.setItem( "is_cart", JSON.stringify(storeData?.data.data.digital_letter.isCart) );
    localStorage.setItem(  "is_login", JSON.stringify(storeData?.data.data.digital_letter.isLogin));

    localStorage.setItem("products", JSON.stringify(storeData.data.data.items));
    localStorage.setItem("categories", JSON.stringify(storeData.data.data.categories));
    localStorage.setItem("modifier_groups", JSON.stringify(storeData.data.data.modifier_groups));
    
    idIsChannelSelect(channelSelect);
    // carta Gourmet 
    if (storeData?.data.data.digital_letter.isGourmet !== null && storeData?.data.data.digital_letter.isGourmet !== undefined) {
      setIsGourmet(storeData?.data.data.digital_letter.isGourmet);
      localStorage.setItem("isGourmet", JSON.stringify(isGourmet));
    }

    if (
      storeData?.data.data.digital_letter.landing_gradient_start !== null &&
      storeData?.data.data.digital_letter.landing_gradient_start !==
        undefined &&
      storeData?.data.data.digital_letter.landing_gradient_end !== null &&
      storeData?.data.data.digital_letter.landing_gradient_end !== undefined
    ) {
      setCurrentColors({
        activo: storeData?.data.data.digital_letter.landing_gradient_start,
        pasivo: storeData?.data.data.digital_letter.landing_gradient_end,
      });
      localStorage.setItem(
        "colors",
        JSON.stringify({
          activo: storeData?.data.data.digital_letter.landing_gradient_start,
          pasivo: storeData?.data.data.digital_letter.landing_gradient_end,
        })
      );
    } else {
      setCurrentColors({
        activo: themes.light.colors.Primarios,
        pasivo: themes.light.colors.Secundarios,
      });
      localStorage.setItem(
        "colors",
        JSON.stringify({
          activo: themes.light.colors.Primarios,
          pasivo: themes.light.colors.Secundarios,
        })
      );
    }

    if (store !== id) {
      localStorage.removeItem("propina");
      deleteBasket();
    }
  };

  const idTokenUser = (token: string, expiration: string) => {
    localStorage.setItem("token_session", token);
    localStorage.setItem("expiration_session", JSON.stringify(expiration));
    setTokenUser(token);
    setExpirationSession(expiration);
  };

  const [culqiModal, setCulqiModal] = useState(false);
  const [amountTotal, setAmountTotal] = useState(0);

  function logearGoogle() {
    console.log("Iniciando sesion con Google");
    try {
      //signInWithGoogle().then((response) => setLoginVisible(false))
    } catch (error) {
      console.error(error);
    }
    signInWithGoogle();
  }
  function logearFacebook() {
    console.log("Iniciando sesion con Facebook");
  }
  function logearApple() {
    console.log("Iniciando sesion con Apple");
  }

  return (
    <UserContext.Provider
      value={{
        store,
        idStore,
        mesa,
        idMesa,
        nameMesa,
        idNameMesa,

        estado,
        idEstado,
        estadoModal,
        idEstadoModal,
        itemCount,
        idItemCount,
        price,
        idPrice,
        priceTotal,
        idPriceTotal,
        basket,
        setBasket,
        storeBasket,
        updateBasket,
        deleteBasket,
        deleteElementFromBasket,
        addOrRemoveProduct,
        client,
        setClient,
        basketModifier,
        setBasketModifier,
        storeBasketModifier,
        // deleteElementFromBasketModifier,
        storeAllBasketModifier,
        addModifier,
        emptyBasketModifiers,

        basketModifierEditar,
        setBasketModifierEditar,
        storeBasketModifierEditar,
        storeAllBasketModifierEditar,
        addModifierEditar,
        emptyBasketModifiersEditar,

        checkModifierOption,
        setCheckModifierOption,
        addCheckAllModifierOption,
        addCheckModifierOption,
        deleteCheckModifierOption,
        emptyCheckModifierOptions,
        AddorRemoveCheckQuantityModifierOptions,
        AddorRemoveTotalQuantityModifierOptions,

        AddorRemoveCheckQuantityModifierOptionsUpdate,
        AddorRemoveTotalQuantityModifierOptionsUpdate,

        handleLocalStorage,

        tokenUser,
        expirationSession,
        idTokenUser,

        culqiModal,
        setCulqiModal,

        amountTotal,
        setAmountTotal,

        digitalLetter,
        idDigitalLetter,

        optionsChannel,
        idOptionsChannel,

        IsCart,
        idIsCart,

        isLogin,
        idIsLogin,

        loginVisble,
        setLoginVisible,

        logearGoogle,
        logearFacebook,
        logearApple,
        userToken,
        tokenExpiration,

        tarjetas,
        customerCulqiId,

        metodoLogeo,
        setMetodoLogeo,

        culqiSecret,
        setCulqiSecret,

        ThemesColors,
        setCurrentColors,

        isGourmet,
        setIsGourmet,

        handleCart,

        name, 
        setName,

        channelSelect,
        idIsChannelSelect

      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
